import Request from '@/components/request/js/index.js';

export default {
  //获取消息列表
  getMessageList() {
    return Request().get('/api/message/user', {
      header: {
        contentType: 'application/json',
      },
      data: {},
    });
  },
  //全部标记已读
  allRead() {
    return Request().post('/api/message/readAll', {
      header: {
        contentType: 'application/json',
      },
      data: {},
    });
  },
  //标记已读
  singleRead(messages_id) {
    return Request().post('/api/message/readMore', {
      header: {
        contentType: 'application/json',
      },
      data: {
        messages_id: messages_id,
      },
    });
  },
};
