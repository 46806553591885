var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-menu',{attrs:{"transition":"slide-y-transition","offset-y":"","offset-x":"","min-width":"400","max-width":"400"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"text-body font-weight-600",class:{
        haveMsg: _vm.msgList.length > 0,
      },attrs:{"depressed":"","color":"transparent","ripple":false}},'v-btn',attrs,false),on),[_c('v-icon',{staticClass:"text-gradient",class:{
          red: _vm.msgList.length > 0,
          indigo: _vm.msgList.length == 0,
        },attrs:{"size":"16"}},[_vm._v("fa fa-bell me-sm-2 text-sm")]),_c('span',{staticClass:"d-sm-inline font-weight-bold d-none text-gradient",class:{
          red: _vm.msgList.length > 0,
          indigo: _vm.msgList.length == 0,
        }},[_vm._v("消息")])],1)]}}])},[_c('v-list',{staticClass:"py-0",staticStyle:{"max-height":"500px","overflow":"scroll","background-color":"#fff'"}},[(_vm.msgList.length == 0)?_c('div',{staticClass:"d-flex justify-center",staticStyle:{"width":"100%","height":"100px","align-items":"center"}},[_c('span',{staticClass:"text-dark"},[_vm._v("暂无消息")])]):_vm._e(),_vm._l((_vm.msgList),function(item,i){return _c('v-list-item',{key:i,staticClass:"pa-4 list-item-hover-active"},[_c('v-list-item-content',{staticClass:"pa-0"},[_c('div',{staticClass:"text-sm font-weight-normal text-typo",domProps:{"innerHTML":_vm._s(item.content)}}),_c('p',{staticClass:"text-xs text-secondary mb-0"},[_c('v-icon',{staticClass:"text-secondary",attrs:{"size":"12"}},[_vm._v("fa fa-clock me-1")]),_vm._v(" "+_vm._s(item.updated_at)+" ")],1)]),_c('v-list-item-action',[_c('div',[_c('a',{staticClass:"text-xs font-weight-normal text-typo py-1",attrs:{"href":"javascript:;"},on:{"click":function($event){return _vm.singleRead(item.id)}}},[_vm._v("标记已读")])])])],1)})],2),_c('div',{staticStyle:{"line-height":"1.5","width":"100%","display":"flex","justify-content":"center","align-items":"center","background-color":"#fff"}},[_c('a',{staticClass:"text-xs font-weight-normal text-typo py-1",attrs:{"href":"javascript:;"},on:{"click":function($event){return _vm.allRead()}}},[_vm._v("全部标记为已读")])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }